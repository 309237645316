#progressBar {
    width: 97%;
    margin: 10px auto;
    height: 22px;
    background-color: #d0ba97;
    border-radius: 25px;
    box-shadow: 0px 10px 10px -8px #33332a, 5px 5px 15px 5px rgb(0 0 0 / 0%);
}

#progressBar div {
    height: 100%;
    text-align: right;
    padding: 0 10px;
    line-height: 22px;
    /* same as #progressBar height if we want text middle aligned */
    width: 0;
    color: #fff;
    background-color: #f6a641;
    box-sizing: border-box;
    transition: width 0.8s;
    font-weight: bold;
    border-radius: 25px;
}