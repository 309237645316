nav {
  background-color: #ffffff;
  /* overflow: hidden; rimosso per lucine natale */
  padding: 0.2rem 0.5rem;
  border-bottom: 1px solid #004775;
  position: sticky;
  top: 0;
  z-index: 100;
}

nav a {
  color: #fff;
}

nav a:visited {
  color: #fff;
}

nav .navWide {
  display: none;
  margin: 0 auto;
}

nav .navWide .wideDiv {
  text-align: center;
}

nav .navWide .wideDiv a {
  text-decoration: none;
  display: inline-block;
  padding: 0 2em;
}

nav .navNarrow i {
  float: left;
  cursor: pointer;
  color: #fff;
}

nav .navNarrow .narrowLinks {
  display: none;
}

nav .navNarrow .narrowLinks a {
  text-decoration: none;
  display: block;
  float: left;
  clear: left;
  padding: 0.5em 0;
}

@media (min-width: 480px) {
  nav .navWide {
    display: block;
  }
  nav .navNarrow {
    display: none;
  }
}

.logo-left {
  height: 52px;
  float: left;
}

.big-avatar {
  height: 2.8em;
  border-radius: 50%;
}

.user-info {
  float: right;
}

.user-info a .userName {
  color: #000;
}

.userName {
  line-height: 2.7em;
  vertical-align: text-bottom;
  margin: 1em;
  font-weight: 500;
  font-size: 1.1em;
}
