a:hover {
    text-decoration: none;
}

.rounded-border {
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 20px;
    height: 5em;
    background-color: white;
}

.rounded-border-mobile {
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 15px;
    height: 3em;
    background-color: white;
}

.small-icon {
    height: 2em;
    width: 2em;
}

.small-icon-mobile {
    height: 1.5em;
    width: 1.5em;
}

.box-title {
    line-height: 2.4em;
    vertical-align: text-bottom;
    font-weight: 500;
    margin-left: 0.5em;
    font-size: 1em;
}

.box-label {
    vertical-align: text-bottom;
    font-weight: 500;
    font-size: 1.3em;
    color: gray;
    margin-left: 2.3em;
    margin-top: 0.5em;
}

.box-label-mobile {
    vertical-align: text-bottom;
    font-weight: 500;
    font-size: 1em;
    color: gray;
    margin-left: 2.3em;
    margin-top: 0.5em;
}

.box-data {
    font-weight: 500;
    text-align: center;
    font-size: 2em;
    margin: 0.5em;
}

.box-data-mobile {
    font-weight: 500;
    text-align: center;
    font-size: 1.6em;
    margin: 0.5em;
}

.container-block {
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}