@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    font-family: 'Nunito', sans-serif;
    line-height: 1.5rem;
}

img {
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.noUnderline {
    text-decoration: none;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
nav {
  background-color: #ffffff;
  /* overflow: hidden; rimosso per lucine natale */
  padding: 0.2rem 0.5rem;
  border-bottom: 1px solid #004775;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

nav a {
  color: #fff;
}

nav a:visited {
  color: #fff;
}

nav .navWide {
  display: none;
  margin: 0 auto;
}

nav .navWide .wideDiv {
  text-align: center;
}

nav .navWide .wideDiv a {
  text-decoration: none;
  display: inline-block;
  padding: 0 2em;
}

nav .navNarrow i {
  float: left;
  cursor: pointer;
  color: #fff;
}

nav .navNarrow .narrowLinks {
  display: none;
}

nav .navNarrow .narrowLinks a {
  text-decoration: none;
  display: block;
  float: left;
  clear: left;
  padding: 0.5em 0;
}

@media (min-width: 480px) {
  nav .navWide {
    display: block;
  }
  nav .navNarrow {
    display: none;
  }
}

.logo-left {
  height: 52px;
  float: left;
}

.big-avatar {
  height: 2.8em;
  border-radius: 50%;
}

.user-info {
  float: right;
}

.user-info a .userName {
  color: #000;
}

.userName {
  line-height: 2.7em;
  vertical-align: text-bottom;
  margin: 1em;
  font-weight: 500;
  font-size: 1.1em;
}

@media screen and (max-width: 600px) {
  .christmas_snow_container {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  margin: 41px 0 0 -0.5rem;
  padding: 0;
  pointer-events: none;
  width: 100%;
  z-index: 99;
}

.lightrope li {
  position: relative;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: #00f7a5;
  box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  -webkit-animation-name: flash-1;
          animation-name: flash-1;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.lightrope li:nth-child(2n + 1) {
  background: cyan;
  box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
  -webkit-animation-name: flash-2;
          animation-name: flash-2;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}

.lightrope li:nth-child(4n + 2) {
  background: #f70094;
  box-shadow: 0px 4.66667px 24px 3px #f70094;
  -webkit-animation-name: flash-3;
          animation-name: flash-3;
  -webkit-animation-duration: 1.1s;
          animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
}

.lightrope li:nth-child(3n + 1) {
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.33333px;
  border-radius: 3px;
  top: -4.66667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.66667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@-webkit-keyframes flash-1 {
  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-1 {
  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@-webkit-keyframes flash-2 {
  0%,
  100% {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px cyan;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-2 {
  0%,
  100% {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px cyan;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@-webkit-keyframes flash-3 {
  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

@keyframes flash-3 {
  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

.page-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
    background-color: antiquewhite;
}

.content-wrap {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 5%;
}

.secondary-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 5em;
}

.bentornato-container {
    margin-top: 5%;
}

.bentornato-bar {
    position: absolute;
    margin-top: 2.5em;
    width: 100%;
    height: 6em;
}

.bentornato-img {
    width: 21em;
    z-index: 100;
}

.bentornato-description {
    margin-top: 3em;
    text-align: center;
}


/* button survey */

.sv_complete_btn,
.sv_start_btn {
    font-size: 1.2rem !important;
    display: inherit !important;
    margin: auto !important;
    padding: 0.2rem 3rem !important;
    font-weight: bold !important;
    float: none !important;
}
.main-footer {
    border-top: 1px solid #004775;
    box-sizing: border-box;
    padding: 0rem 0.5rem;
    background-color: #fff;
    position: relative;
    display: -webkit-flex;
    display: flex;
    bottom: 0;
    width: 100%;
    color: #004775;
    text-align: left;
    -webkit-align-items: center;
            align-items: center;
    line-height: normal;
    font-size: 0.9rem;
}

.main-footer a {
    color: #004775;
}

.main-footer .image {
    margin-left: auto;
}

.main-footer .image a {
    display: -webkit-flex;
    display: flex;
}

.main-footer .image .logo {
    height: 2.5rem;
}

@media only screen and (max-width: 600px) {
    .main-footer {
        display: block;
        text-align: center;
    }
    .main-footer .image a {
        display: block;
    }
    .main-footer .image {
        margin: auto;
    }
}
.middle-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

a:hover {
    text-decoration: none;
}

.rounded-border {
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 20px;
    height: 5em;
    background-color: white;
}

.rounded-border-mobile {
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 15px;
    height: 3em;
    background-color: white;
}

.small-icon {
    height: 2em;
    width: 2em;
}

.small-icon-mobile {
    height: 1.5em;
    width: 1.5em;
}

.box-title {
    line-height: 2.4em;
    vertical-align: text-bottom;
    font-weight: 500;
    margin-left: 0.5em;
    font-size: 1em;
}

.box-label {
    vertical-align: text-bottom;
    font-weight: 500;
    font-size: 1.3em;
    color: gray;
    margin-left: 2.3em;
    margin-top: 0.5em;
}

.box-label-mobile {
    vertical-align: text-bottom;
    font-weight: 500;
    font-size: 1em;
    color: gray;
    margin-left: 2.3em;
    margin-top: 0.5em;
}

.box-data {
    font-weight: 500;
    text-align: center;
    font-size: 2em;
    margin: 0.5em;
}

.box-data-mobile {
    font-weight: 500;
    text-align: center;
    font-size: 1.6em;
    margin: 0.5em;
}

.container-block {
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
#progressBar {
    width: 97%;
    margin: 10px auto;
    height: 22px;
    background-color: #d0ba97;
    border-radius: 25px;
    box-shadow: 0px 10px 10px -8px #33332a, 5px 5px 15px 5px rgb(0 0 0 / 0%);
}

#progressBar div {
    height: 100%;
    text-align: right;
    padding: 0 10px;
    line-height: 22px;
    /* same as #progressBar height if we want text middle aligned */
    width: 0;
    color: #fff;
    background-color: #f6a641;
    box-sizing: border-box;
    transition: width 0.8s;
    font-weight: bold;
    border-radius: 25px;
}
h1,
h2 {
    margin-top: 0;
}

a {
    color: #000;
}

hr {
    margin: 20px 0px;
    border: 1px solid #ddd;
}

@media screen and (max-width: 992px) {
    hr {
        margin: 10px 0px 0px 0px;
    }
}

a:hover {
    text-decoration: none;
}

.section-header {
    margin: 20px;
    text-align: center;
}

.section-header .icon {
    height: 4em;
    width: 4em;
}

.text-center {
    text-align: center;
}

.small-icon {
    height: 2em;
    width: 2em;
}

.box-title {
    line-height: 2.4em;
    vertical-align: text-bottom;
    font-weight: 500;
    margin-left: 0.5em;
    font-size: 1em;
}

.box-label {
    vertical-align: text-bottom;
    font-weight: 500;
    font-size: 1em;
    color: gray;
    margin-left: 0em;
    margin-top: 0.5em;
    text-align: center;
}

.avatar-big {
    height: 8em;
    border-radius: 50%;
    float: right;
}

.main-box {
    margin: 1.2em;
    padding: 15px 0;
    position: relative;
}

.rounded-border {
    border-radius: 15px;
    border: 3px solid #73AD21;
    padding: 12px;
    height: 10em;
    margin: 10px;
}

.rounded-border img {
    height: 4em;
    width: 4em;
}

.box-data {
    font-weight: 600;
    text-align: center;
    font-size: 2em;
    margin-top: 0.3em;
    text-align: center;
}

.edit-btn {
    width: 2em;
    float: right;
    clear: both;
}

.link-rules {
    position: absolute;
    top: 0px;
    right: 0px;
}

.icon-rules {
    width: 3rem;
}
.table-header {
    background-color: #ddd;
    line-height: normal !important;
    font-size: 16px !important;
}

.MuiTableCell-root {
    padding: 10px !important;
}

.currentUserRow {
    background-color: #ececec;
}

.avatar {
    height: 2.8em;
    border-radius: 50%;
    float: left;
}

.nickname {
    line-height: 2.7em;
    vertical-align: text-bottom;
    margin-left: 1em;
    float: left;
}

.btn-leader-board {
    margin-top: 40px;
    cursor: pointer;
    color: #000;
    border-radius: 10px;
    padding: 10px 20px;
    background: #eee;
    border: 2px solid #ddd;
}

.btn-leader-board:hover {
    background: #fff;
}

@media screen and (max-width: 768px) {
    .hide-mobile {
        display: none !important;
    }
}
.page-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
    background-color: #374e7233;
}

.error-container {
    margin-top: 5%;
}

.error-bar {
    background-color: #83a7bb;
    position: absolute;
    margin-top: 2.5em;
    width: 100%;
    height: 6em;
}

.error-img {
    width: 20em;
    z-index: 100;
}

.error-description {
    margin-top: 3em;
    text-align: center;
}

.content-wrap {
    border-radius: 10px;
    -webkit-flex: 1 1;
            flex: 1 1;
    /*margin: 5%; */
    margin: 20px 40px;
}

@media screen and (max-width: 992px) {
    .content-wrap {
        margin: 10px;
    }
}
