.main-footer {
    border-top: 1px solid #004775;
    box-sizing: border-box;
    padding: 0rem 0.5rem;
    background-color: #fff;
    position: relative;
    display: flex;
    bottom: 0;
    width: 100%;
    color: #004775;
    text-align: left;
    align-items: center;
    line-height: normal;
    font-size: 0.9rem;
}

.main-footer a {
    color: #004775;
}

.main-footer .image {
    margin-left: auto;
}

.main-footer .image a {
    display: flex;
}

.main-footer .image .logo {
    height: 2.5rem;
}

@media only screen and (max-width: 600px) {
    .main-footer {
        display: block;
        text-align: center;
    }
    .main-footer .image a {
        display: block;
    }
    .main-footer .image {
        margin: auto;
    }
}