.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #374e7233;
}

.error-container {
    margin-top: 5%;
}

.error-bar {
    background-color: #83a7bb;
    position: absolute;
    margin-top: 2.5em;
    width: 100%;
    height: 6em;
}

.error-img {
    width: 20em;
    z-index: 100;
}

.error-description {
    margin-top: 3em;
    text-align: center;
}

.content-wrap {
    border-radius: 10px;
    flex: 1;
    /*margin: 5%; */
    margin: 20px 40px;
}

@media screen and (max-width: 992px) {
    .content-wrap {
        margin: 10px;
    }
}