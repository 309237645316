@media screen and (max-width: 600px) {
  .christmas_snow_container {
    transform: translateY(-100%);
  }
}

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  margin: 41px 0 0 -0.5rem;
  padding: 0;
  pointer-events: none;
  width: 100%;
  z-index: 99;
}

.lightrope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: #00f7a5;
  box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  animation-name: flash-1;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n + 1) {
  background: cyan;
  box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n + 2) {
  background: #f70094;
  box-shadow: 0px 4.66667px 24px 3px #f70094;
  animation-name: flash-3;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n + 1) {
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.33333px;
  border-radius: 3px;
  top: -4.66667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.66667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@keyframes flash-1 {
  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-2 {
  0%,
  100% {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px cyan;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-3 {
  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}
