.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: antiquewhite;
}

.content-wrap {
    flex: 1;
    margin: 5%;
}

.secondary-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5em;
}

.bentornato-container {
    margin-top: 5%;
}

.bentornato-bar {
    position: absolute;
    margin-top: 2.5em;
    width: 100%;
    height: 6em;
}

.bentornato-img {
    width: 21em;
    z-index: 100;
}

.bentornato-description {
    margin-top: 3em;
    text-align: center;
}


/* button survey */

.sv_complete_btn,
.sv_start_btn {
    font-size: 1.2rem !important;
    display: inherit !important;
    margin: auto !important;
    padding: 0.2rem 3rem !important;
    font-weight: bold !important;
    float: none !important;
}