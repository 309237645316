h1,
h2 {
    margin-top: 0;
}

a {
    color: #000;
}

hr {
    margin: 20px 0px;
    border: 1px solid #ddd;
}

@media screen and (max-width: 992px) {
    hr {
        margin: 10px 0px 0px 0px;
    }
}

a:hover {
    text-decoration: none;
}

.section-header {
    margin: 20px;
    text-align: center;
}

.section-header .icon {
    height: 4em;
    width: 4em;
}

.text-center {
    text-align: center;
}

.small-icon {
    height: 2em;
    width: 2em;
}

.box-title {
    line-height: 2.4em;
    vertical-align: text-bottom;
    font-weight: 500;
    margin-left: 0.5em;
    font-size: 1em;
}

.box-label {
    vertical-align: text-bottom;
    font-weight: 500;
    font-size: 1em;
    color: gray;
    margin-left: 0em;
    margin-top: 0.5em;
    text-align: center;
}

.avatar-big {
    height: 8em;
    border-radius: 50%;
    float: right;
}

.main-box {
    margin: 1.2em;
    padding: 15px 0;
    position: relative;
}

.rounded-border {
    border-radius: 15px;
    border: 3px solid #73AD21;
    padding: 12px;
    height: 10em;
    margin: 10px;
}

.rounded-border img {
    height: 4em;
    width: 4em;
}

.box-data {
    font-weight: 600;
    text-align: center;
    font-size: 2em;
    margin-top: 0.3em;
    text-align: center;
}

.edit-btn {
    width: 2em;
    float: right;
    clear: both;
}

.link-rules {
    position: absolute;
    top: 0px;
    right: 0px;
}

.icon-rules {
    width: 3rem;
}