.table-header {
    background-color: #ddd;
    line-height: normal !important;
    font-size: 16px !important;
}

.MuiTableCell-root {
    padding: 10px !important;
}

.currentUserRow {
    background-color: #ececec;
}

.avatar {
    height: 2.8em;
    border-radius: 50%;
    float: left;
}

.nickname {
    line-height: 2.7em;
    vertical-align: text-bottom;
    margin-left: 1em;
    float: left;
}

.btn-leader-board {
    margin-top: 40px;
    cursor: pointer;
    color: #000;
    border-radius: 10px;
    padding: 10px 20px;
    background: #eee;
    border: 2px solid #ddd;
}

.btn-leader-board:hover {
    background: #fff;
}

@media screen and (max-width: 768px) {
    .hide-mobile {
        display: none !important;
    }
}